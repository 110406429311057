import React from 'react';
import { Link } from 'gatsby';

const PostLink = ({ post }) => (
  <article>
    <a href={post.frontmatter.slug} className="image">
      <img src={post.frontmatter.image} alt="" />
    </a>
    <h3 className="major">
      {post.frontmatter.title} - {post.frontmatter.date}
    </h3>
    <a href={post.frontmatter.slug} className="special">
      Read
    </a>
  </article>
);

export default PostLink;
