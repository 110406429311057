import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import PostLink from '../components/Post-link';

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />);

  return (
    <Layout fullMenu>
      <section id="wrapper">
        <header>
          <div className="inner">
            <h2>Blog</h2>
            <p>My thoughts on stuff...</p>
          </div>
        </header>

        <div className="wrapper">
          <div className="inner">
            <section className="features">{Posts}</section>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            image
          }
        }
      }
    }
  }
`;
